import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import InnerImageZoom from "react-inner-image-zoom";

import config from "../../../../config/config.json";
import ModuleVariants from "./modules/ModuleVariants";
import ModuleActions from "./modules/ModuleActions";
import ModuleDeliveryOption from "./modules/ModuleDeliveryOption";
import NextArrow from "../../carousel/NextArrow";
import PrevArrow from "../../carousel/PrevArrow";
import ModuleProductDetail from "./modules/ModuleProductDetail";
import ModuleProductReviews from "./modules/ModuleProductReviews";
import SimilarProduct from "../../../../pages/product/SimilarProduct";
import { calculateProductPriceOffer } from "../../../../utilities/product-helper";
import { currencyPrice } from "../../../../hooks/currencyCalculation";
import useGetProducts from "../../../../hooks/useGetProducts";
import ModuleSuggestedProduct from "./modules/ModuleSuggestedProduct";
import ModuleProductFaq from "./modules/ModuleProductFaq";
import ModuleProductDetailDescription from "../modules/ModuleProductDetailDescription";
import ModuleProductTags from "./modules/ModuleProductTags";

const DetailOptionOne = ({
  product,
  comboproduct,
  currencyrate,
  likeproduct,
  productvariant,
  productviewcount,
  productfaq,
}) => {
  const { id } = useParams();
  const { productReview, getProductReviewById } = useGetProducts();

  const [currentProduct, setCurrentProduct] = useState(product);
  const [isVariantFlag, setIsVariantFlag] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  const isMobile = window.innerWidth <= 768;

  let imagepath = config.apiUrl + config.image_path;

  useEffect(() => {
    getProductReviewById(id);
  }, [id]);

  useEffect(() => {
    let imageArray = [];

    if (currentProduct) {
      if (currentProduct.productvarientimagelist?.length > 0) {
        currentProduct.productvarientimagelist.forEach((item) => {
          imageArray.push(item.imagename ? `${imagepath}${item.imagename}` : "/static/img/noproduct-images.png");
        });
      } else {
        if (currentProduct.imagename) {
          imageArray.push(`${imagepath}${currentProduct.imagename}`);
        } else {
          imageArray.push("/static/img/noproduct-images.png");
        }
        if (currentProduct.productimagelist?.length > 0) {
          currentProduct.productimagelist.forEach((item) => {
            imageArray.push(item.imagename ? `${imagepath}${item.imagename}` : "/static/img/noproduct-images.png");
          });
        }
      }
    } else {
      imageArray.push("/static/img/noproduct-images.png");
    }

    setImages(imageArray);
  }, [currentProduct]);

  const mainSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);

  const thumbnailSettings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    focusOnSelect: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ref: thumbnailSliderRef,
    beforeChange: (current, next) => setCurrentImageIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: images.length > 5 ? 5 : images.length,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          initialSlide: 0,
          centered: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: images.length > 5 ? 5 : images.length,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          initialSlide: 0,
          centered: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: images.length > 5 ? 5 : images.length,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          initialSlide: 0,
          centered: false,
        },
      },
    ],
  };

  const mainSliderSettings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ref: mainSliderRef,
    beforeChange: (_, next) => setCurrentImageIndex(next),
    afterChange: (index) => thumbnailSliderRef.current.slickGoTo(index),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
        },
      },
    ],
  };

  const handleVariantFlagChange = (flag) => {
    setIsVariantFlag(flag);
  };

  useEffect(() => {
    if (!(productvariant && productvariant.length > 0) && currentProduct.price > 0 && currentProduct.stock > 0) {
      setIsVariantFlag(true);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (thumbnailSliderRef.current) {
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, [thumbnailSliderRef]);

  return (
    <>
      <div className="detail-one-container ">
        <div className="top-section ">
          <div className="detail-one--left container col-12 col-md-7 p-0 mb-20 w-100">
            {images.length > 0 && (
              <>
                <div className="img-slider container col-12 col-md-2 p-0 m-0">
                  <Slider
                    {...thumbnailSettings}
                    ref={thumbnailSliderRef}
                    asNavFor={mainSliderRef.current}
                    slickGoTo={thumbnailSliderRef.current}
                    prevArrow={
                      <button>
                        <i className="icon-chevron-up"></i>
                      </button>
                    }
                    nextArrow={
                      <button>
                        <i className="icon-chevron-down"></i>
                      </button>
                    }
                    className="thumbnail-slider"
                  >
                    {images.map((image, index) => (
                      <div key={index} className="thumbnail-item">
                        <img src={image} className="img-fluid" />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="main-image-slider container col-12 col-md-9 p-0">
                  <Slider
                    {...mainSliderSettings}
                    ref={mainSliderRef}
                    asNavFor={thumbnailSliderRef.current}
                    slickGoTo={currentImageIndex}
                    prevArrow={
                      <button>
                        <i className="icon-chevron-up"></i>
                      </button>
                    }
                    nextArrow={
                      <button>
                        <i className="icon-chevron-down"></i>
                      </button>
                    }
                  >
                    {images.map((image, index) => (
                      <div key={index} className="main-slider-image container p-0">
                        <div
                          className="zoom-image-wrapper"
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <InnerImageZoom
                            src={image}
                            zoomSrc={image}
                            zoomScale={1.3}
                            moveType={isMobile ? "pan" : "drag"}
                            zoomType={"click"}
                            type
                            hideCloseButton={true}
                            className="zoom-image"
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </>
            )}
          </div>
          {currentProduct && (
            <div className="detail-one--right col-12 col-md-5 pr-0 pl-0">
              <div className="name-rating-container">
                <div className="container p-0">
                  <p>{currentProduct.productbrand ? currentProduct.productbrand : ""}</p>
                  <h3>
                    {currentProduct.productname}{" "}
                    <div className="badge-container">
                      <span className={`badge ${currentProduct.stock === 0 ? "color-red" : "color-green"}`}>
                        {currentProduct.stock > 0 ? "In Stock" : "Out of Stock"}
                      </span>
                    </div>{" "}
                  </h3>
                  <div className="rating-container">
                    <div className="container p-0">
                      {currentProduct.rating > 0 && (
                        <>
                          <span className="rating">{currentProduct.rating} ⭐ </span>
                          <span className="mr-10">|</span>
                        </>
                      )}
                      <span className="total mr-5">
                        {productReview && productReview.productreviewlist !== null
                          ? productReview.productreviewlist.length
                          : "No"}
                      </span>
                      <span className="total">Review(s)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="price-container">
                <div className="container p-0 d-flex align-items-center">
                  {currentProduct.offerprice && currentProduct.offerprice > 0 ? (
                    <>
                      <h3>{currencyPrice(currentProduct.offerprice, currencyrate)}</h3>
                      <span className="original-price">{currencyPrice(currentProduct.price, currencyrate)}</span>
                      <span className="offer">({calculateProductPriceOffer(currentProduct)}% OFF)</span>
                    </>
                  ) : (
                    <h3>{currencyPrice(currentProduct.price, currencyrate)}</h3>
                  )}
                </div>
              </div>
              <div className="tax-delivery-container">
                <span>[</span>
                <span className="tax ml-2">Inclusive of taxes</span> +
                <div className="delivery">
                  <span>
                    <img src="/static/img/logo/shipping.png" />
                  </span>
                  <span className="ml-2 text">Free Delivery</span>
                </div>
                <span>]</span>
              </div>
              <div className="short-desc-container">
                <ModuleProductDetailDescription product={product} />
              </div>
              {productvariant && productvariant.length > 0 && (
                <ModuleVariants
                  product={currentProduct}
                  setProduct={setCurrentProduct}
                  productvariant={productvariant}
                  onVariantFlagChange={handleVariantFlagChange}
                />
              )}
              <div className="container p-0">
                <ModuleActions product={currentProduct} productflag={isVariantFlag} />
              </div>
              <ModuleDeliveryOption />
              <div className="product-tags-container container p-0">
                <ModuleProductTags product={currentProduct} />
              </div>
              <ModuleSuggestedProduct comboproduct={comboproduct} currencyrate={currencyrate} />
            </div>
          )}
        </div>
        <div className="product-detail-section container p-0 m-0">
          <ModuleProductDetail product={product} />
        </div>
        <div className="middle-section container p-0 m-0">
          <ModuleProductReviews
            images={images}
            productReview={productReview}
            product={currentProduct}
            productviewcount={productviewcount}
          />
        </div>
        {productfaq && productfaq.length > 0 ? (
          <div className="product-faq-container  mb-40">
            <ModuleProductFaq productfaq={productfaq} />
          </div>
        ) : (
          <div className="mt-40"></div>
        )}
        {likeproduct && likeproduct.length > 0 && (
          <div className="col-12 similar-product p-0">
            <SimilarProduct likeproduct={likeproduct} currencyrate={currencyrate} />
          </div>
        )}
      </div>
    </>
  );
};

export default DetailOptionOne;
