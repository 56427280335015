import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
} from "react-share";

import config from "../../config/config.json";
import useHeader from "../../hooks/useHeader";
import useUser from "../../hooks/useUser";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "Reward Points",
    url: "/reward",
  },
  {
    id: 3,
    text: "Reward",
  },
];

const MyReward = ({ ecomerce }) => {
  const { username } = useHeader();
  const { userReward, userRewardHistory, getUserRewards, getUserRewardsHistory } = useUser();
  const navigate = useNavigate();

  const [disable, setDisable] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showShareOption, setShowShareOption] = useState(false);
  const [copySuccess, setCopySuccess] = useState("Copy Code");

  const code = userReward && userReward.refcode;
  const shareUrl = `${config.applicationUri}signup?referralcode=${code}`;
  const title = "Join us and earn rewards!";

  const toggleHistory = () => {
    setShowHistory((prevState) => !prevState);
  };

  const toggleShare = () => {
    setShowShareOption((prevState) => !prevState);
  };

  useEffect(() => {
    if (username) {
      getUserRewards();
      getUserRewardsHistory();
      window.scrollTo(0, 0);
    } else {
      navigate("/login");
    }
  }, [ecomerce]);

  const copyToClipboard = () => {
    const referralCode = code;

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(referralCode)
        .then(() => {
          setCopySuccess("Code copied!");
          setTimeout(() => setCopySuccess("Copy Code"), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy referral link: ", err);
        });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = referralCode;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setCopySuccess("Code copied!");
      setTimeout(() => setCopySuccess("Copy Code"), 2000);
    }
  };

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content ">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Reward Points"} />
                </div>
                <div className="ps-layout__right ">
                  <div className="ps-page__content  ">
                    <div className="row">
                      <div className="col-12 col-md-12 ps-form--review rewards">
                        <h2 className="ps-form__title mb-20">Reward Points</h2>
                        <div className="container p-0">
                          <div className="row ml-0 mr-0 mb-30">
                            <div className="total-reward container p-0 d-flex">
                              <div
                                className="col-6 pl-0 pr-0 total-reward-container card-box-shadow"
                                style={{
                                  backgroundImage: `url("/static/img/background/reward_bg.png")`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  objectFit: "contain",
                                }}
                              >
                                <div className="card-box-shadow total-reward-box text-center container p-30">
                                  <p>Total Reward Points</p>
                                  <h1>{userReward && userReward.rewardpoints}</h1>
                                </div>
                              </div>
                              <div className="col-6 invite pr-0">
                                <div className="container p-0">
                                  <div className="referal-code-container card-box-shadow">
                                    <div className="referal-content container p-0">
                                      <div>
                                        <h3>Invite Friends</h3>
                                        <p className="title-font">
                                          Earn Unlimited FREE points! Your friends get 50 points when they sign up with
                                          your invite code and you also earn 50 points.
                                        </p>
                                        <p className="title title-font">Refferal Code</p>
                                      </div>
                                      <div className="code-box">
                                        <span className="code-text">{code}</span>
                                        <span className="copy-text" onClick={copyToClipboard}>
                                          {copySuccess}
                                        </span>
                                      </div>
                                      <div className="share-btn" onClick={toggleShare}>
                                        {showShareOption ? (
                                          <button className="w-100">Not Now</button>
                                        ) : (
                                          <button className="w-100">Share With Your Friends</button>
                                        )}
                                      </div>
                                      {showShareOption && (
                                        <div className="share-options row ml-0 mr-0">
                                          <div className="col-2 text-center p-0">
                                            <div className="row ml-0 mr-0 mb-10 d-flex align-items-center justify-content-center">
                                              <FacebookShareButton url={shareUrl} quote={title}>
                                                <img
                                                  src="/static/img/logo/facebook.png"
                                                  height={50}
                                                  width={50}
                                                  className="object-fit-contain"
                                                />
                                              </FacebookShareButton>
                                            </div>
                                          </div>
                                          <div className="col-2 text-center p-0">
                                            <div className="row ml-0 mr-0 mb-10 d-flex align-items-center justify-content-center">
                                              <FacebookMessengerShareButton
                                                appId={config.facebook_appid}
                                                url={shareUrl}
                                              >
                                                <img
                                                  src="/static/img/logo/messenger.png"
                                                  height={50}
                                                  width={50}
                                                  className="object-fit-contain"
                                                />
                                              </FacebookMessengerShareButton>
                                            </div>
                                          </div>
                                          <div className="col-2 text-center p-0">
                                            <div className="row ml-0 mr-0 mb-10 d-flex align-items-center justify-content-center">
                                              <TwitterShareButton url={shareUrl} title={title}>
                                                <img
                                                  src="/static/img/logo/twitterlogo.png"
                                                  height={50}
                                                  width={50}
                                                  className="object-fit-contain"
                                                />
                                              </TwitterShareButton>
                                            </div>
                                          </div>
                                          <div className="col-2 text-center p-0">
                                            <div className="row ml-0 mr-0 mb-10 d-flex align-items-center justify-content-center">
                                              <TelegramShareButton url={shareUrl} title={title}>
                                                <img
                                                  src="/static/img/logo/telegram.png"
                                                  height={50}
                                                  width={50}
                                                  className="object-fit-contain"
                                                />
                                              </TelegramShareButton>
                                            </div>
                                          </div>
                                          <div className="col-2 text-center p-0">
                                            <div className="row ml-0 mr-0 mb-10 d-flex align-items-center justify-content-center">
                                              <WhatsappShareButton url={shareUrl} title={title}>
                                                <img
                                                  src="/static/img/logo/whatsapp.png"
                                                  height={50}
                                                  width={50}
                                                  className="object-fit-contain"
                                                />
                                              </WhatsappShareButton>
                                            </div>
                                          </div>
                                          <div className="col-2 text-center p-0">
                                            <div className="row ml-0 mr-0 mb-10 d-flex align-items-center justify-content-center">
                                              <LinkedinShareButton url={shareUrl} title={title}>
                                                <img
                                                  src="/static/img/logo/linkedin.png"
                                                  height={50}
                                                  width={50}
                                                  className="object-fit-contain"
                                                />
                                              </LinkedinShareButton>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" view-history-link">
                            {showHistory ? (
                              <Button onClick={toggleHistory}>Hide Reward Point History</Button>
                            ) : (
                              <Button onClick={toggleHistory}>View Reward Point History</Button>
                            )}
                          </div>
                          {showHistory && (
                            <div className="row m-0">
                              <div className="col-12 p-0 reward-content-container ">
                                {userRewardHistory &&
                                  userRewardHistory.length > 0 &&
                                  userRewardHistory.map((reward, index) => (
                                    <div className="content card-box-shadow row ml-0 mr-0" key={index}>
                                      <div className="event-name col-6 p-0">
                                        <h3>{reward.event}</h3>
                                        <p className="title-font">
                                          {reward.note && reward.note.length > 0 ? reward.note : " "}
                                        </p>
                                      </div>
                                      <div className="points col-3 p-0 text-center">
                                        <p className={`${reward.rewardpoint >= 0 ? "color-green" : "color-red"}`}>
                                          {reward.rewardpoint} Points
                                        </p>
                                      </div>
                                      <div className="date col-3 p-0 text-center">
                                        <p className="title-font">{reward.date}</p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect((state) => state)(MyReward);
