import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ProductRepository from "../api/ProductRepository";
import useGetProducts from "./useGetProducts";

const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getProductMaxMin } = useGetProducts();

  const navigate = useNavigate();
  const { categoryid } = useParams();

  const [isMenu, setIsMenu] = useState(false);
  const [dropdowns, setDropdowns] = useState({});

  const [selectedSizes, setSelectedSizes] = useState([]);
  const [priceRange, setPriceRange] = useState([
    Number(searchParams.get("minimumprice")) || 0,
    Number(searchParams.get("maximumprice")) || 100000,
  ]);
  const [priceChanged, setPriceChanged] = useState(false);
  const [checkedFilters, setCheckedFilters] = useState({});
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({
    priceRange: [],
    selectedFilters: [],
    selectedRatings: [],
  });

  useEffect(() => {
    getProductRating();
    window.scrollTo(0, 0);
  }, [searchParams.get("rating")]);

  useEffect(() => {
    getProductMaxMin();
  }, [searchParams]);

  useEffect(() => {
    const minPrice = Number(searchParams.get("minimumprice")) || 0;
    const maxPrice = Number(searchParams.get("maximumprice")) || 100000;
    setPriceRange([minPrice, maxPrice]);
  }, [searchParams]);

  /*
    To get rating list
  */
  async function getProductRating() {
    const ratinglist = await ProductRepository.getProductRating();
    if (ratinglist) {
      setRatingData(ratinglist);
    } else {
      return null;
    }
  }

  /*
    To display drawer
  */
  const showFilters = () => {
    setIsMenu(true);
  };

  /*
    To close drawer
  */
  const closeFilters = () => {
    setIsMenu(false);
    setDropdowns(false);
  };

  /*
    To toggle dropdowns
  */
  const toggleDropdown = (filterName) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [filterName]: !prevState[filterName],
    }));
  };

  /*
    To handle price filter
  */
  const handlePriceChange = (value) => {
    setPriceRange(value);
    setPriceChanged(true);
  };

  /*
   To handle rating filter
 */
  const handleRatingChange = (ratingValue) => {
    let newRatings = new Set(selectedRatings);

    if (newRatings.has(ratingValue)) {
      newRatings.delete(ratingValue);
    } else {
      newRatings.add(ratingValue);
    }

    setSelectedRatings(Array.from(newRatings));
  };

  /*
   To handle other filters
 */
  const handleCheckboxChange = (filterName, subFilterLabel) => {
    if (subFilterLabel === undefined) {
      return;
    }

    const newCheckedFilters = {
      ...checkedFilters,
      [filterName]: {
        ...checkedFilters[filterName],
        [subFilterLabel]: !checkedFilters[filterName]?.[subFilterLabel],
      },
    };
    setCheckedFilters(newCheckedFilters);
  };

  /*
  To remove all applied filters
*/
  const clearAllFilters = () => {
    searchParams.delete("minimumprice");
    searchParams.delete("maximumprice");
    searchParams.delete("category");
    searchParams.delete("filters");
    searchParams.delete("rating");
    setSearchParams(searchParams);

    setPriceRange([0, 1000000]);
    setSelectedSizes([]);
    setCheckedFilters({});
    setSelectedRatings([]);
    setPriceChanged(false);

    const resetCheckedFilters = Object.fromEntries(
      Object.entries(checkedFilters).map(([filterName, subFilters]) => [
        filterName,
        Object.fromEntries(Object.keys(subFilters).map((subFilterLabel) => [subFilterLabel, false])),
      ])
    );

    setCheckedFilters(resetCheckedFilters);
  };

  /*
    To apply all filters
  */
  const applyFilter = () => {
    if (priceChanged) {
      searchParams.set("minimumprice", priceRange[0]);
      searchParams.set("maximumprice", priceRange[1]);
    }

    const selectedFilters = Object.entries(checkedFilters).flatMap(([filterName, subFilters]) =>
      Object.keys(subFilters).filter((label) => subFilters[label])
    );

    const existingFilters = searchParams.get("filters") ? searchParams.get("filters").split(",") : [];
    const existingRatings = searchParams.get("rating") ? searchParams.get("rating").split(",") : [];

    const filtersToRemove = existingFilters.filter((filter) => !selectedFilters.includes(filter));
    const ratingsToRemove = existingRatings.filter((rating) => !selectedRatings.includes(rating));

    const updatedFilters = [...new Set([...selectedFilters, ...existingFilters])].filter(
      (filter) => !filtersToRemove.includes(filter)
    );
    const updatedRatings = [...new Set([...selectedRatings, ...existingRatings])].filter(
      (rating) => !ratingsToRemove.includes(rating)
    );

    if (updatedFilters.length > 0) {
      searchParams.set("filters", updatedFilters.join(","));
    } else {
      searchParams.delete("filters");
    }

    if (updatedRatings.length > 0) {
      searchParams.set("rating", updatedRatings.join(","));
    } else {
      searchParams.delete("rating");
    }

    setAppliedFilters({
      priceRange: priceRange[0] > 0 || priceRange[1] < 1000000 ? `price:${priceRange[0]}-${priceRange[1]}` : null,
      selectedFilters: updatedFilters,
      selectedRatings: selectedRatings,
    });

    searchParams.set("pageno", "1");
    setIsMenu(false);
    setPriceChanged(false);
    setSearchParams(searchParams);
  };

  return {
    searchParams,
    isMenu,
    dropdowns,
    ratingData,
    priceRange,
    checkedFilters,
    selectedRatings,
    setSelectedRatings,
    showFilters,
    closeFilters,
    toggleDropdown,
    handlePriceChange,
    clearAllFilters,
    setCheckedFilters,
    handleRatingChange,
    handleCheckboxChange,
    applyFilter,
  };
};

export default useFilter;
