import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import FormButton from "../../components/elements/form/FormButton";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import PaaswordInput from "../../components/elements/form/PaaswordInput";
import SelectBox from "../../components/elements/form/SelectBox";
import Container from "../../components/layouts/Container";
import shopconfig from "../../config/shopconfig.json";
import useEcomerce from "../../hooks/useEcomerce";
import Logo from "../../components/elements/basic/Logo";

var countrycodearray = [];

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("Please enter value for Firstname")
    .matches(/^[a-zA-Z]+$/, "Please enter valid Firstname"),
  password: Yup.string()
    .matches(/^[^\s*]/g, "White Space is not allowed")
    .required("Please enter value for Password"),
  lastname: Yup.string()
    .required("Please enter value for Lastname")
    .matches(/^[a-zA-Z]+$/, "Please enter valid Lastname"),
  phonenumber: Yup.string()
    .matches(/^[0-9]+$/, "Please enter valid Mobile No")
    .min(10, "Please enter valid Mobile No")
    .required("Please enter value for Mobile No"),
  phonecountry: Yup.string().nullable().required("Please enter value for Country Code"),
  email: Yup.string().nullable().email("Please enter valid value for email"),
});

const Signup = () => {
  const location = useLocation();

  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState([false]);
  const [selectedcountry, setSelectedCountry] = useState({ value: "+91", label: "+91" });

  const { countrylist, getCountryList } = useEcomerce();

  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("referralcode");

  /*
  To initial values json
*/
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phonecountry: "+91",
    phonenumber: "",
    status: "1",
    loginevent: "signup",
    password: "",
    referredby: referralCode || "",
  };

  useEffect(() => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
      getCountryList();
    }, 500);

    window.scrollTo(0, 0);
  }, []);

  /*
    To set country in array
  */
  if (countrylist && countrylist.length > 0) {
    countrycodearray = [];
    countrylist.map((item, index) => {
      var countrycodeobject = new Object();
      countrycodeobject["value"] = item.extension;
      countrycodeobject["label"] = item.extension;
      countrycodearray.push(countrycodeobject);
    });
  }

  /*
    To  create user aand set data local storage 
  */
  const createUser = async (data) => {
    setDisable(true);
    localStorage.setItem("userid", "");
    localStorage.setItem("event", "");
    const res = await user.register(data);
    if (res.data.status === 200) {
      if (data.email !== "") {
        localStorage.setItem("SendOtpOn", data.email);
        localStorage.setItem("event", "Signup OTP");
        localStorage.setItem("SendOtp", "emailid");
        localStorage.setItem("otpfrom", "email");
      } else if (data.email === "" && data.phonenumber) {
        localStorage.setItem("SendOtpOn", data.phonenumber);
        localStorage.setItem("event", "Signup OTP");
        localStorage.setItem("SendOtp", "contactno");
        localStorage.setItem("SendOtp", "contactno");
        localStorage.setItem("otpfrom", "email");
        localStorage.setItem("SendOtp", "contactno");
        localStorage.setItem("otpfrom", "email");
        localStorage.setItem("otpfrom", "phonenumber");
        localStorage.setItem("phonecountry", data.phonecountry);
      }
      localStorage.removeItem("EventOtp");
      setTimeout(() => {
        setDisable(false);
        window.location.href = "/otpverification";
      }, 500);
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = res.data.errors.message;
    }
  };

  /*
    To country view
  */
  let country = [];
  if (countrylist && countrylist.length > 0) {
    country.push(<option>Select Country</option>);
    if (countrylist.length > 0) {
      for (var i = 0; i < countrylist.length; i++) {
        country.push(
          <option value={countrylist[i].extension} key={i}>
            {countrylist[i].extension}
          </option>
        );
      }
    }
  }

  /*
    To password hide / show
  */
  const passwordHideORShow = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const hideValidation = () => {
    document.getElementById("errormsg").classList.add("hide");
  };

  return (
    <Container>
      <div className="ps-page  form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}

        <GoogleCaptcha />
        <div className="container">
          <div className="ps-page__header"></div>
          <div className="ps-page__content ps-account">
            <div className="row ml-0 mr-0 justify-content-center">
              <div className="content-card-signup  box-rounded-login  mt-70 mb-70 login align-self-center">
                <div className="signup-card-content">
                  <div className=" br-1 login-welcome text-center login-mobile-hide">
                    <div className="row height-100-per">
                      <div className="col-12 ps-form--review form-padding">
                        <h3>Welcome To </h3>
                        <Logo />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="ps-form--review  form-padding">
                      <FormikFrom
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                          createUser(values);
                        }}
                      >
                        <Form>
                          <h2 className="ps-form__title text-center">Create New Account</h2>
                          <div className="row mt-20">
                            <div className="col-12 col-md-6">
                              <InputField
                                label={"First Name *"}
                                name={"firstname"}
                                type={"text"}
                                placeholder={"First Name"}
                                maxLength={"50"}
                              />
                            </div>
                            <div className="col-12 col-md-6">
                              <InputField
                                label={"Last Name *"}
                                name={"lastname"}
                                type={"text"}
                                placeholder={"Last Name"}
                                maxLength={"50"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-5">
                              <SelectBox
                                label={"Country *"}
                                name={"phonecountry"}
                                options={countrycodearray}
                                placeholder={"Country"}
                                setSelectedCountry={setSelectedCountry}
                                initialValues={initialValues}
                                value={selectedcountry}
                              />
                            </div>
                            <div className="col-12 col-md-7">
                              <InputField
                                label={"Mobile No *"}
                                name={"phonenumber"}
                                type={"text"}
                                placeholder={"Mobile No"}
                                maxLength={"10"}
                                onKeyUp={(e) => {
                                  hideValidation();
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                label={"Email"}
                                name={"email"}
                                type={"email"}
                                placeholder={"Email"}
                                maxLength={"50"}
                                onKeyUp={(e) => {
                                  hideValidation();
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <PaaswordInput
                                label={"Password *"}
                                name={"password"}
                                type={show ? "password" : "text"}
                                placeholder={"Password"}
                                maxLength={"50"}
                                autocomplete="new-password"
                                onClick={passwordHideORShow}
                                show={show}
                              />
                            </div>
                          </div>
                          <div className="row hide">
                            <div className="col-12 ">
                              <InputField
                                label={"RefeRral Code"}
                                name={"referredby"}
                                type={"text"}
                                value={initialValues.referredby}
                              />
                            </div>
                          </div>
                          <span className="color-red hide pt-10" id="errormsg"></span>
                          <p className="mb-20 terms-section">
                            By signing up you agree the{" "}
                            <a href="/termsandcondition" className="color-2nd">
                              terms and condition
                            </a>{" "}
                            ,{" "}
                            <a href="/privacypolicy" className="color-2nd">
                              {" "}
                              privacy policy
                            </a>{" "}
                            and
                            <a href="/returnpolicy" className="color-2nd">
                              {" "}
                              return policy
                            </a>
                          </p>
                          <div className="ps-form__submit mt-10">
                            <FormButton
                              name={"Signup"}
                              type="submit"
                              className={
                                disable
                                  ? "ps-btn ps-btn--warning width-100 disabled"
                                  : "ps-btn ps-btn--warning width-100"
                              }
                            />
                          </div>
                          <div className="row mt-10">
                            <div className="col-12 col-md-12 text-center">
                              <span className="linktext">Already have an account ?</span>
                              <Link to={"/login"}>
                                <span className="ps-account__link">Login</span>
                              </Link>
                            </div>
                          </div>
                        </Form>
                      </FormikFrom>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Signup;
