import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import useEcomerce from "../../hooks/useEcomerce";
import { calculateCartQuantity } from "../../utilities/ecomerce-helpers";
import LocalstorageParam from "../common/LocalstorageParam";
import CartParameter from "./CartParameter";
import OrderSummary from "./OrderSummary";

const CartOrderSummary = ({ ecomerce }) => {
  const { loading, cart_list, getCartlist, setCartlist } = useEcomerce();
  const [billingaddress, setBillingAddressData] = useState();
  const [handelloader, setHandelloader] = useState(false);
  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();
  let cartdata = cart_list;
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shop",
      url: "/shop",
    },
    {
      text: "Shopping cart",
      url: "/cart?coupon=" + searchparam.urlcoupon + "&rewardpoints=" + searchparam.urlpoints,
    },
    {
      text: "Checkout",
      url: "/checkout?coupon=" + searchparam.urlcoupon + "&rewardpoints=" + searchparam.urlpoints,
    },
    {
      text: "Order Summary",
    },
  ];

  useEffect(() => {
    setHandelloader(true);
    setTimeout(() => {
      setHandelloader(false);
      if (ecomerce.cartItems) {
        getCartlist(ecomerce.cartItems, "cart");
      }
    }, 500);

    setBillingAddress();
  }, [ecomerce]);

  /*
    To set billing address in state variable
  */
  const setBillingAddress = () => {
    var localparam = LocalstorageParam();
    if (localparam.billingdata && localparam.billingdata !== "undefined") {
      setBillingAddressData(JSON.parse(localparam.billingdata));
    }
  };

  return (
    <React.Fragment>
      <Container title="Shopping Cart">
        <div className="form-loader">
          {handelloader && (
            <div className="ps-section__overlay_delayed">
              <div className="ps-section__loading"></div>
            </div>
          )}

          <div className="ps-page ps-page--shopping">
            <div className="container">
              <div className="ps-page__header">
                <BreadCrumb breacrumb={breadcrumb} />
                <h1 className="ps-page__heading">
                  Order Summary
                  {cartdata && cartdata.length > 0 && <sup>({calculateCartQuantity(cartdata)})</sup>}
                </h1>
              </div>
              <OrderSummary
                loading={loading}
                cartdata={cart_list}
                setCartlist={setCartlist}
                billingaddress={billingaddress}
                setHandelloader={setHandelloader}
              />
            </div>
          </div>
        </div>
      </Container>
      <div className="text-center hide" id="payumoneydiv">
        <h1 className="title-font capital title-newsletter font-size-25">
          Please do not refresh the page or press back button while we redirect you to payment gateway !
          &nbsp;&nbsp;&nbsp;
        </h1>
        <p className="des-font">
          <img src="/static/img/common/loader.gif" alt="" />
        </p>
      </div>
    </React.Fragment>
  );
};

export default connect((state) => state)(CartOrderSummary);
