import axios from "axios";

import config from "../config/config.json";

const headers = {
  Accept: "*/*",
};

/*
  To user signup
*/
const register = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/user/create";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      if (data.loginevent !== "googlelogin") {
        localStorage.setItem("userid", response.data.payload);
        localStorage.setItem("event", "Signup OTP");
      }
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To forget password
*/
const forgetpassword = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/forgetpassword";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

/*
  To verifyotp
*/
const verifyotp = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/verifyotp";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

/*
  To reset password
*/
const resetpassword = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/setpassword";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return resp;
};

/*
  To resend otp
*/
const resendotp = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/resendotp";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

/*
  To get country list
*/
const getCountry = async () => {
  const url = config.apiUrl + config.apiurlname + "/country";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To update user
*/
const updateUser = async (data) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/update";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To user detail
*/
const getUserDetail = async () => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/detail";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To change password
*/
const changePassword = async (data) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/changepassword";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

/*
  To get state list
*/
const getState = async (countryid) => {
  const url = config.apiUrl + config.apiurlname + "/state/" + countryid;
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To save password
*/
const saveAddress = async (data) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");
  const url = config.apiUrl + config.apiurlname + "/user/saveaddress";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To get address list
*/
const getAddress = async (addressobject) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/getaddress";
  const respo = await axios
    .post(url, addressobject, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To delete address
*/
const deleteAddress = async (data) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/deleteaddress";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To update address
*/
const updateAddress = async (data) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/updateaddress";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To update isdefault address
*/
const isDefaultAddressUpdate = async (addressobject) => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/user/isdefaultaddressupdate";
  const resp = await axios
    .post(url, addressobject, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return resp;
};

/*
  To verify username for login
*/
const verifyUserEmailORMobile = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/user/verify";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

/*
  To send otp
*/
const sendOtp = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/sendotp";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return resp;
};

/*
  To send otp for login
*/
const sendOtpforLogin = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/sendloginotp";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

/*
  To login with otp
*/
const loginWithOtp = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/user/otplogin";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return resp;
};

/*
  To login with otp
*/
const loginOtp = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/user/loginwithotp";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return resp;
};

/*
  To verify google capcha
*/
const verifyGoogleCapcha = async (token) => {
  var data = new Object();
  data["recaptcha"] = token;

  const url = config.apiUrl + config.apiurlname + "/verifycaptcha";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return resp;
};

/*
  To save news latter
*/
const saveNewsLetter = async (data) => {
  const url = config.apiUrl + config.apiurlname + "/savenewsletter";
  const respo = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return respo;
};

/*
  To get user rewards count
*/
const getUserRewards = async () => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/rewardpoint";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To get user rewards history
*/
const getUserRewardsHistory = async () => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/rewardpoint/history";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

/*
  To get user rewards max use and amount
*/
const getUserRewardsAndAmount = async () => {
  headers.Authorization = "Bearer " + localStorage.getItem("authToken");

  const url = config.apiUrl + config.apiurlname + "/rewardpointandamount";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

export default {
  register,
  forgetpassword,
  verifyotp,
  resetpassword,
  resendotp,
  getCountry,
  updateUser,
  getUserDetail,
  changePassword,
  getState,
  saveAddress,
  getAddress,
  deleteAddress,
  updateAddress,
  isDefaultAddressUpdate,
  verifyUserEmailORMobile,
  sendOtp,
  loginWithOtp,
  verifyGoogleCapcha,
  sendOtpforLogin,
  loginOtp,
  saveNewsLetter,
  getUserRewards,
  getUserRewardsHistory,
  getUserRewardsAndAmount,
};
