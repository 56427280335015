import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useEcomerce from "../../../../../hooks/useEcomerce";
import LocalstorageParam from "../../../../../pages/common/LocalstorageParam";

const ModuleActions = ({ product, ecomerce, productflag }) => {
  const [quantity, setQuantity] = useState(1);
  const { loading, addItem } = useEcomerce();
  const [disable, setDisable] = useState(false);
  const [alreadyinwishlist, setAlreadyinwishlist] = useState(product.alreadyinwishlist);

  const navigate = useNavigate();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To add item to cart
  */
  const handleAddItemToCart = (e) => {
    e.preventDefault();

    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      addItem(
        {
          productid: product.id,
          quantity: quantity,
          productvariantid: product.variantid,
        },
        ecomerce.cartItems,
        "cart"
      );
    } else {
      navigate("/login");
    }
  };

  /*
    To add item to wishlist
  */
  const handleAddItemToWishlist = (e) => {
    e.preventDefault();

    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      addItem(
        {
          productid: product.id,
          productvariantid: 0,
        },
        ecomerce.wishlistItems,
        "wishlist"
      );
      setAlreadyinwishlist(1);
    } else {
      navigate("/login");
    }
  };

  /*
    To increase item qty
  */
  const handleIncreaseItemQty = (e) => {
    e.preventDefault();
    let qty = Number(quantity) + 1;
    if (Number(product.stock) >= qty) {
      setDisable(false);
      setQuantity(qty);
    } else {
      setDisable(true);
    }
  };

  /*
    To decrease item qty
  */
  const handleDecreaseItemQty = (e) => {
    e.preventDefault();
    let qty;
    if (quantity > 1) {
      qty = Number(quantity) - 1;
      setQuantity(qty);
    }
    if (qty >= 1) {
      if (Number(product.stock) >= qty) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  };

  return (
    <div className="container p-0">
      <div className="qty-container">
        <div className="container p-0  d-flex justify-content-between">
          <label className="qty">Qunatity : </label>
          <div className="qty-btn">
            <button
              onClick={(e) => handleDecreaseItemQty(e)}
              className={Number(product.quantity) >= Number(product.stock) ? "disabled" : ""}
            >
              -
            </button>
            <span>|</span>
            <input type="text" value={quantity} disabled />
            <span>|</span>
            <button
              onClick={(e) => handleIncreaseItemQty(e)}
              className={Number(product.quantity) <= 0 ? " disable" : ""}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="cart-wishlist-container">
        <div className="container p-0 d-flex justify-content-evenly">
          {alreadyinwishlist === null || Number(alreadyinwishlist) === Number(0) ? (
            <button className="btn wishlist-btn" onClick={(e) => handleAddItemToWishlist(e)}>
              <i className="icon-heart"></i>ADD TO WISHLIST
            </button>
          ) : (
            <button className="btn wishlist-btn lighter" disabled>
              <i className="icon-heart"></i>ADDED TO WISHLIST
            </button>
          )}
          {productflag ? (
            <button className="btn cart-btn" onClick={(e) => handleAddItemToCart(e)}>
              <i className="icon-bag"></i>ADD TO CART
            </button>
          ) : (
            <button className="btn cart-btn lighter" disabled>
              <i className="icon-bag"></i>ADD TO CART
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state)(ModuleActions);
