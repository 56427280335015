import { useState } from "react";

import user from "../api/user";

const useUser = () => {
  const [userReward, setUserReward] = useState(null);
  const [userRewardHistory, setUserRewardHistory] = useState(null);
  const [userRewardUseAndAmount, setUserRewardUseAndAmount] = useState(null);

  /*
    To get user rewards count
  */
  const getUserRewards = async () => {
    const res = await user.getUserRewards();
    if (res.data.status === 200) {
      setUserReward(res.data.payload);
    }
  };

  /*
    To get user rewards history
  */
  const getUserRewardsHistory = async () => {
    const res = await user.getUserRewardsHistory();
    if (res.data.status === 200) {
      setUserRewardHistory(res.data.payload);
    }
  };

  /*
  To get user rewards max use and amount
*/
  const getUserRewardsAndAmount = async () => {
    const res = await user.getUserRewardsAndAmount();
    if (res.data.status === 200) {
      setUserRewardUseAndAmount(res.data.payload);
    }
  };

  return {
    userReward,
    getUserRewards,
    userRewardHistory,
    getUserRewardsHistory,
    userRewardUseAndAmount,
    getUserRewardsAndAmount,
  };
};

export default useUser;
