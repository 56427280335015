import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import home from "../api/home";
import useLocalStorage from "./useLocalStorage";
import LocalstorageParam from "../pages/common/LocalstorageParam";
import ProductRepository from "../api/ProductRepository";
import { toggleDrawer } from "../store/app/action";
import useEcomerce from "./useEcomerce";

const useHeader = () => {
  const { blankLocalStorage } = useLocalStorage();
  const { wishlistandcartCount, getWishlistAndCartCount } = useEcomerce();
  const { ckeckLoginToken } = useEcomerce();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isMenu, setIsMenu] = useState(false);
  const [homedata, setHomeData] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [hideNavTwo, setHideNavTwo] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [product, setProduct] = useState(null);
  const [isProductListVisible, setIsProductListVisible] = useState(true);

  useEffect(() => {
    ckeckLoginToken();
    setIsMenu(false);
    if (username) {
      getWishlistAndCartCount();
    }
  }, []);

  /*
    To get sticky header height
  */
  useEffect(() => {
    const stickyThreshold = 88;
    const hideThreshold = 130;

    const handleScroll = debounce(() => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > stickyThreshold);
      setHideNavTwo(scrollY > hideThreshold);
    }, 200);
    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*
    To get homedata
  */
  const getHomeData = async () => {
    const responseData = await home.getHomeData();
    if (responseData) {
      setHomeData(responseData.data.payload);
    }
  };

  /*
  To get search product
*/
  async function getSearchProduct(search) {
    var object = new Object();
    object["search"] = search;
    const productdata = await ProductRepository.getSearchProduct(object);
    if (productdata) {
      setProduct(productdata);
    } else {
      return null;
    }
  }

  /*
    To get URL 
  */
  var baseUrl = window.location.pathname;
  var url;
  if (baseUrl.startsWith("/category")) {
    url = baseUrl + "?";
  } else if (baseUrl.startsWith("/collections")) {
    url = baseUrl + "?";
  } else {
    url = "/shop?";
  }

  /*
    To set search keyword on state variable 
  */
  function handleSetKeyword(e) {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    if (newKeyword) {
      getSearchProduct(newKeyword);
      setIsProductListVisible(true);
    } else {
      setProduct(null);
      setIsProductListVisible(false);
    }
  }

  /*
To redirect to shop url 
*/
  function handleSubmit(e) {
    e.preventDefault();
    redirectProduct();
  }

  /*
   To redirect url 
 */
  const redirectProduct = () => {
    const params = new URLSearchParams(location.search);

    if (keyword && keyword.trim() !== "") {
      setKeyword(keyword);
      params.set("searchkey", keyword);
      params.set("pageno", "1");
      navigate(`${url}${params.toString()}`);
      setIsProductListVisible(false);
    } else {
      params.delete("searchkey");
      params.set("pageno", "1");
      setKeyword("");
      navigate(`${url}${params.toString()}`);
    }
  };
  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  let username = "";
  let firstname = "";
  let fullname = "";
  if (
    localparam !== "" &&
    localparam.decoded !== "" &&
    localparam.decoded.firstname !== null &&
    localparam.decoded.firstname !== ""
  ) {
    username = localparam.decoded.firstname.charAt(0) + localparam.decoded.lastname.charAt(0);
    firstname = localparam.decoded.firstname;
    fullname = localparam.decoded.firstname + " " + localparam.decoded.lastname;
  }

  let totalcart = 0;
  let totalwishlist = 0;
  /*
    To set username,cart and wishlist countin variable
  */
  if (username) {
    totalcart = wishlistandcartCount.totalcartcount;
    totalwishlist = wishlistandcartCount.totalwishlistcount;
  }

  /*
  To redirect login page
*/
  const login = () => {
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  };

  /*
    To profile and redirect to profile page
  */
  const profile = () => {
    setTimeout(() => {
      navigate("/profile");
    }, 500);
  };

  /*
   To logout and redirect to home page
 */
  const logout = () => {
    blankLocalStorage();
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const toggleSearch = () => {
    setIsSearchOpen((prevState) => !prevState);
  };

  /*
    To open model and remove overflow-auto class from body
  */
  const handleOpenDrawer = (e) => {
    e.preventDefault();
    dispatch(toggleDrawer(true));
    document.body.classList.remove("overflow-auto");
  };

  /*
    To close menu
  */
  function handleCloseMenu(e) {
    e.preventDefault();
    setIsMenu(false);
  }
  /*
    To open menu
    */
  function handleOpenMenu(e) {
    e.preventDefault();
    setIsMenu(true);
  }

  return {
    url,
    isMenu,
    isSticky,
    hideNavTwo,
    homedata,
    getHomeData,
    firstname,
    username,
    fullname,
    totalcart,
    totalwishlist,
    login,
    profile,
    logout,
    isSearchOpen,
    toggleSearch,
    keyword,
    product,
    isProductListVisible,
    setIsProductListVisible,
    navigate,
    setKeyword,
    setIsSearchOpen,
    handleSetKeyword,
    handleSubmit,
    handleOpenDrawer,
    handleCloseMenu,
    handleOpenMenu,
  };
};

export default useHeader;
